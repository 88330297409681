import React, { Component } from "react";
import Card from "../UI/Card";
import { SelectButton } from 'primereact/selectbutton';

class Cv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value1: '',
      options: [],
      resumeList: [],
    };
  }

  async componentDidMount() {
    const response = await fetch('https://cpanel.fi-erme.com/api/liste-cvs?populate=CV_PDF&pagination[start]=0&pagination[limit]=100');
    const json = await response.json();
    const resumeList = json.data.map(item => item.attributes);
    const options = [...new Set(resumeList.map(item => `Promo ${item.Promo}`))].sort((a, b) => b.split(' ')[1] - a.split(' ')[1]);
    this.setState({ resumeList, options, value1: options[0] });
  }

  render() {
    const { value1, options, resumeList } = this.state;
    const filteredList = resumeList.filter(item => `Promo ${item.Promo}` === value1);

    return (
      <div className="">
        <Card>
          <div className="mt-4">
            <h1 className="text-center">Liste des CV</h1>
          </div>
          <div style={{display: 'flex', flexDirection: 'row',  justifyContent:'center', alignItems:'center'}}>
            <SelectButton value={value1} options={options} onChange={(e) => this.setState({ value1: e.value })} />
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card card-primary">
                    <div className="card-body table-responsive p-0">
                      <table className="table table-striped table-valign-middle">
                        <thead>
                          <tr>
                            <th>Nom Complet</th>
                            <th>Email</th>
                            <th>CV</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredList.map((cv) => {
                            return (
                              <tr key={cv.id}>
                                <td>{cv.Nom_Complet}</td>
                                <td>{cv.Email}</td>
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    // onClick=""
                                  >
                                    <a style={{color:'white'}} href={cv.CV_PDF.data.attributes.url} target="_blank">
                                      Télécharger
                                    </a>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default Cv;